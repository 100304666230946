.select{
    position: relative;
  }
  
  .select-header {
    display: flex;
    justify-content: space-between;
    padding: 0.2rem 0.4rem;
    border: 1px #ccc solid;
    border-radius: 5px;
    cursor: pointer;
    width: 180px;
  }
  
  .select-icon {
    /* display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-shrink: 0;
    margin-top: 0.3rem;
    margin-left: 0.3rem;
    border-style: solid;
    border-width: 10px 5px 0 5px;
    border-color: rgba(184, 0, 141, 0.67) transparent transparent transparent;
    transform-origin: 50% 30% 0; */
    transition: all .3s ease 
  }
  
  .select-icon.active-icon{
    transform:rotate(180deg);
  }
  
  .select-body{
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    z-index: 100;
    background-color: white;
    display: none;
    /* opacity: 0; */
    /* visibility: hidden; */
    /* transition: all .5s linear; */
  }

  .select-body.active-body{
      display: block;
      /* opacity: 1; */
      /* visibility: visible; */
  }
  
  .select-item{
    border: 1px #ccc solid;
    border-top: 0;
    cursor: pointer;
    padding: 0.2rem 0.4rem;
  }
  
  .select-item:hover {
    background-color: #f2f2f2;
  }
