.field_file_fake {
  border: solid 1px gray;
  border-right: none;
  height: 36px;
  display: flex;
  align-content: center;
  align-items: center;
  border-radius: 2px;
  padding: 0 16px;
  box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 12%),
    0 1px 5px 0 rgb(0 0 0 / 20%);
    white-space: nowrap;
}
